<template>
  <div>
    <v-card class="card-shadow">
      <v-card-text class="px-0 py-0">
        <v-data-table
          :headers="headers"
          :items="items"
          class="table table-striped"
          hide-default-footer
          mobile-breakpoint="0"
          :items-per-page="paginationDetails.itemsPerPage"
        >
          <template v-slot:top>
            <v-toolbar flat height="80">
              <v-row>
                <v-col cols="12" md="7">
                  <v-text-field
                    v-if="showSearchBox"
                    hide-details
                    class="input-style font-size-input text-light-input placeholder-light input-icon"
                    dense
                    flat
                    filled
                    solo
                    height="43"
                    v-model="search"
                    :placeholder="searchPlaceholder"
                  >
                    <template slot="prepend-inner">
                      <v-icon color="#adb5bd" size=".875rem"
                        >fas fa-search</v-icon
                      >
                    </template>
                  </v-text-field>
                </v-col>
              </v-row>
              <div
                v-if="tableTitle"
                class="card-header-padding card-border-bottom"
              >
                <div class="font-weight-600 text-h3 text-typo mb-1">
                  {{ tableTitle }}
                </div>
                <p class="text-body font-weight-thin text-body-2 ls-0 mb-0">
                  {{ tableSubTitle }}
                </p>
              </div>
              <v-spacer></v-spacer>

              <v-select
                v-if="showFilterBy"
                outlined
                dense
                class="input-style font-size-input text-light-input placeholder-light mt-7"
                :placeholder="filterByPlaceholder"
                :items="filterbyItems"
                :item-text="filterbyItemText"
                :item-value="filterbyItemValue"
                v-model="filterBy"
              >
              </v-select>

              <v-menu
                v-if="showFilterByDate"
                ref="dateFilterMenu"
                v-model="dateFilterMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    outlined
                    dense
                    v-model="dateFilter"
                    :placeholder="filterByDatePlaceholder"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="input-style font-size-input text-light-input placeholder-light ml-5 mt-7"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="dateFilter"
                  @input="dateFilterMenu = false"
                ></v-date-picker>
              </v-menu>

              <v-btn
                v-if="showFilterBy || showFilterByDate"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm ms-3"
                color="#8b8b8b"
                @click="clearFilters"
                >clear filters
              </v-btn>

              <v-btn
                v-if="showCreateNewItemButton"
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm ms-3"
                color="#5e72e4"
                @click="createNewItem"
                ><v-icon class="me-3">ni-fat-add</v-icon>
                {{ createNewItemLabel }}
              </v-btn>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card class="card-shadow card-padding">
                  <v-card-title class="pt-0 text-h4 text-typo justify-center"
                    >Are you sure you want to delete this item?</v-card-title
                  >
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="closeDelete"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-600 text-capitalize btn-ls btn-danger py-3 px-6 rounded-sm"
                      color="#f5365c"
                      >Cancel</v-btn
                    >

                    <v-btn
                      @click="deleteItemConfirm"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-600 text-capitalize btn-ls btn-primary py-3 px-6 rounded-sm"
                      color="#5e72e4"
                      >Ok</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogApprove" max-width="500px">
                <v-card class="card-shadow card-padding">
                  <v-card-title class="pt-0 text-h4 text-typo justify-center">
                    {{ approveConfirmationText }}</v-card-title
                  >
                  <v-card-actions class="pb-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      @click="closeApprove"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-600 text-capitalize btn-ls btn-danger py-3 px-6 rounded-sm"
                      color="#f5365c"
                      >Cancel</v-btn
                    >

                    <v-btn
                      @click="approveItemConfirm"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-600 text-capitalize btn-ls btn-primary py-3 px-6 rounded-sm"
                      color="#5e72e4"
                      >Approve</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
            <v-row v-if="showTotalValue" class="pr-5">
              <v-col cols="12" style="text-align: right">
                <strong>{{ totalValueText }}: R {{ totalValue }}</strong>
              </v-col>
            </v-row>
            <v-row v-if="showTotalValue" class="pr-5">
              <v-col cols="12" style="text-align: right">
                <strong>{{ subTotalValueText }}: R {{ subTotalValue }}</strong>
              </v-col>
            </v-row>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="enabledActionEdit"
                  v-bind="attrs"
                  v-on="on"
                  @click="editItem(item)"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="font-weight-600 text-capitalize btn-ls btn-warning me-2 my-2 rounded-sm"
                  color="#40a0fb"
                >
                  <v-icon size="12">ni-palette</v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="enabledActionDeactivate"
                  v-bind="attrs"
                  v-on="on"
                  @click="updateItemStatus(item)"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="font-weight-600 text-capitalize btn-ls btn-danger me-2 my-2 rounded-sm"
                  :color="getDeactivateButtonColour(item)"
                >
                  <v-icon v-if="item.status == 'active'" size="12">
                    ni-button-pause
                  </v-icon>
                  <v-icon v-if="item.status != 'active'" size="12">
                    ni-button-play
                  </v-icon>
                </v-btn>
              </template>
              <span>{{
                item.status == "active" ? "Deactivate" : "Activate"
              }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="enabledActionDelete"
                  v-bind="attrs"
                  v-on="on"
                  @click="deleteItem(item)"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="font-weight-600 text-capitalize btn-ls btn-danger me-2 my-2 rounded-sm"
                  color="#f5365c"
                >
                  <v-icon size="12">ni-fat-remove</v-icon>
                </v-btn>
              </template>
              <span>{{ deleteTooltip }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="enabledActionGoToItemPage"
                  v-bind="attrs"
                  v-on="on"
                  @click="goToItemPage(item)"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="font-weight-600 text-capitalize btn-ls btn-danger me-2 my-2 rounded-sm"
                  color="#9c27b0"
                >
                  <v-icon size="12">ni-zoom-split-in</v-icon>
                </v-btn>
              </template>
              <span>View</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="enabledActionApprove"
                  v-bind="attrs"
                  v-on="on"
                  @click="approveItem(item)"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="font-weight-600 text-capitalize btn-ls btn-danger me-2 my-2 rounded-sm"
                  color="#44ab4a"
                >
                  <v-icon size="12">{{ approveIcon }}</v-icon>
                </v-btn>
              </template>
              <span>{{ approveTooltip }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="enabledActionAssignPacker"
                  v-bind="attrs"
                  v-on="on"
                  @click="assignPacker(item)"
                  elevation="0"
                  :ripple="false"
                  height="28"
                  min-width="36"
                  width="36"
                  class="font-weight-600 text-capitalize btn-ls btn-danger me-2 my-2 rounded-sm"
                  color="#44ab4a"
                >
                  <v-icon size="12">ni-cart</v-icon>
                </v-btn>
              </template>
              <span>Assign Packer</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import debounce from "debounce";

export default {
  name: "paginated-tables",
  props: {
    paginationDetails: {
      default: () => ({
        itemsPerPage: 10,
      }),
      type: Object,
      required: false,
    },
    createNewItemLabel: {
      default: "Create New Item",
      type: String,
      required: false,
    },
    enabledActionEdit: {
      default: true,
      type: Boolean,
      required: false,
    },
    enabledActionDeactivate: {
      default: true,
      type: Boolean,
      required: false,
    },
    enabledActionDelete: {
      default: false,
      type: Boolean,
      required: false,
    },
    enabledActionGoToItemPage: {
      default: false,
      type: Boolean,
      required: false,
    },
    enabledActionApprove: {
      default: false,
      type: Boolean,
      required: false,
    },
    enabledActionAssignPacker: {
      default: false,
      type: Boolean,
      required: false,
    },
    approveTooltip: {
      default: "Approve",
      type: String,
      required: false,
    },
    approveConfirmationText: {
      type: String,
      default: "Are you sure you want to approve this item?",
      required: false,
    },
    approveIcon: {
      type: String,
      default: "ni-check-bold",
      required: false,
    },
    deleteTooltip: {
      default: "Delete",
      type: String,
      required: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    showSearchBox: {
      default: false,
      type: Boolean,
      required: false,
    },
    searchPlaceholder: {
      default: "Search",
      type: String,
      required: false,
    },
    showCreateNewItemButton: {
      type: Boolean,
      default: true,
    },
    showFilterBy: {
      type: Boolean,
      default: false,
      required: false,
    },
    filterByIdentifier: {
      type: String,
      default: "",
      required: false,
    },
    filterByPlaceholder: {
      type: String,
      default: "Filter...",
      required: false,
    },
    filterbyItems: {
      type: Array,
      default: () => [],
      required: false,
    },
    filterbyItemText: {
      type: String,
      default: "",
      required: false,
    },
    filterbyItemValue: {
      type: String,
      default: "",
      required: false,
    },
    showFilterByDate: {
      type: Boolean,
      default: false,
      required: false,
    },
    filterByDatePlaceholder: {
      type: String,
      default: "Select date...",
      required: false,
    },
    tableSubTitle: {
      default: "",
      type: String,
      required: false,
    },
    tableTitle: {
      default: "",
      type: String,
      required: false,
    },
    showTotalValue: {
      default: false,
      type: Boolean,
      required: false,
    },
    totalValueText: {
      default: "Total Value",
      type: String,
      required: false,
    },
    totalValue: {
      default: "",
      type: String,
      required: false,
    },
    subTotalValueText: {
      default: "Sub Total Value",
      type: String,
      required: false,
    },
    subTotalValue: {
      default: "",
      type: String,
      required: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      newItemsPerPage: 0,
      dialog: false,
      dialogApprove: false,
      dialogDelete: false,
      dialogUpdateStatus: {
        item: {},
        status: "active",
        text: "",
        visible: false,
      },
      checkboxModel: [],
      search: "",
      editedIndex: -1,
      debounceTimeout: {
        updateItemsPerPage: null,
      },
      idToApprove: 0,
      idToDelete: 0,
      filterBy: null,
      dateFilterMenu: null,
      dateFilter: null,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    pages() {
      return this.pagination.rowsPerPage
        ? Math.ceil(this.items.length / this.pagination.rowsPerPage)
        : 0;
    },
  },
  methods: {
    approveItem(item) {
      this.idToApprove = item.id;
      this.dialogApprove = true;
    },

    approveItemConfirm() {
      this.$emit("approveItem", this.idToApprove);
      this.closeApprove();
    },

    assignPacker(item) {
      this.$emit("assignPacker", item);
    },

    clearFilters() {
      this.search = null;
      this.filterBy = null;
      this.dateFilterMenu = null;
      this.dateFilter = null;
      this.$emit("clearFilters");
    },

    close() {
      this.dialog = false;
    },

    closeApprove() {
      this.dialogApprove = false;
    },

    closeDelete() {
      this.dialogDelete = false;
    },

    createNewItem() {
      this.$emit("createNewItem");
    },

    deleteItem(item) {
      this.idToDelete = item.id;
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.$emit("deleteItem", this.idToDelete);
      this.closeDelete();
    },

    editItem(item) {
      this.$emit("editItem", item);
      this.dialog = true;
    },

    emitUpdateSearchValue: debounce(function () {
      this.$emit("updateSearchValue", this.search);
    }, 500),

    getDeactivateButtonColour(item) {
      if (item.status == "active") {
        return "#fb6340";
      }

      return "#2dce89";
    },

    goToItemPage(item) {
      this.$emit("goToItemPage", item);
    },

    paginateFetchFromUrl(direction) {
      if (direction === "next") {
        this.$emit("fetchNextPage");
      } else {
        this.$emit("fetchPreviousPage");
      }
    },

    updateItemStatus(item) {
      let status = "";
      if (["active"].includes(item.status)) {
        status = "inactive";
      } else {
        status = "active";
      }

      this.$emit("updateItemStatus", item, status);
    },

    updateItemsPerPage(newValue) {
      clearTimeout(this.debounceTimeout.updateItemsPerPage);

      this.debounceTimeout.updateItemsPerPage = setTimeout(() => {
        this.$emit("updateItemsPerPage", newValue);
      }, 1000);
    },

    updateSelectedPage(newValue) {
      this.$emit("updateSelectedPage", newValue);
    },
  },
  mounted() {},
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    search(val) {
      this.emitUpdateSearchValue();
    },
    filterBy(val) {
      if (val) {
        this.$emit("updateFilterBy", this.filterByIdentifier, val);
      }
    },
    dateFilter(val) {
      if (val) {
        this.$emit("updateFilterBy", "date", val);
      }
    },
  },
};
</script>
